const jQuery = require('jquery');
const slick = require('slick-carousel');

var $j = jQuery.noConflict();
var hoverIntent = false; var hoverIntentInt;

$j(document).ready(function () {
    // smooth scroll from external anchor, im keeping just in case, but its annoying
    if (window.location.hash !== '') {
        smoothScrollOnLoad();
    }

    onClickLoadMoreButton();
    mobileMenuToggle();
    addMenuActiveClass();
    smoothScroll();
    tockButtonStyle();

    // initSlick();
    initSlickWorkshops();
    initSlickPartnersforgood();
    initSlickinitiatives();
    initSlickResourcesClaimYour();
    initSlickResourcesPastInitiative();

    navigation();

    accordion();
    playYTVideo();
    //changePushState(''); -> go home

    videoModalEvents();
    initResourceFilters();
    initInstructions();

    animateBlocks(); // when visible on viweport

    redirectTo("/events-chicago.html", "/events.html");

    $j(".grants-instructions.--fixed-container .toggle").on("click", function (e) {
        $j(".grants-instructions.--fixed-container > div").addClass("--once").toggleClass("--open");
    });

    /*
    $j(".two-column .grid-item").on("mouseenter", function () {
      if (!hoverIntent) { 
        clearInterval(hoverIntentInt);
        $j(this).addClass("hover");
        hoverIntent = true;
      }
    });
  
    $j(".two-column .grid-item").on("mouseleave", function () {
      var that = this;
      hoverIntentInt = setInterval(function () {  
        $j(that).removeClass("hover");
        if ($j(that).parents(".--grid-container").filter(function() { return $j(this).is(":hover"); }).length == 0) {
          hoverIntent = false;
        }
      }, 200);
    });
    */


    $j('.menu-toggle').off().on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        $j(this).toggleClass("active");
        $j(".site-header__nav").toggleClass("active");
        return false;
    });

    $j(window).on("resize", function () {
        var mH = 0;
        //$j("#sweepsiframe").attr("style", "");

        $j(".mission-cpartners .right-section h6").each(function () {
            $j(this).css("height", "auto");
            mH = Math.max(mH, $j(this).height());
        });
        $j(".mission-cpartners .right-section h6").each(function () {
            $j(this).height(mH);
        });


        var headerHeight = $j(".site-header").height();
        $j(".grants-instructions.--sticky>div").css("top", headerHeight);


        if ($j(".modal.-active").length > 0) {
            var h = window.outerHeight - 360;
            if (window.innerWidth >= 1000) {
                $j(".modal__wrapper").width("70vw");
            } else {
                h = window.outerHeight - 180;
                $j(".modal__wrapper").width("100%");
            }

            if ($j(".modal__video-ctn").height() - 1 > ($j(".modal__wrapper").outerHeight() - 85)) {
                $j(".modal__wrapper").width(Math.min(h * 1.7777777778, window.outerWidth-20));
            }
        }

    });
    setTimeout(function () { $j(window).trigger("resize") }, 1000);

});


// - - - Add a smoothScroll for anchor nav links - - -
function smoothScroll() {
    $j(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();

        var hash = this.hash;
        var scrollTo = 0;
        if (hash == "#apply") {
            scrollTo += $j(".hero-section").outerHeight();
            scrollTo += $j(".desc-section").outerHeight();
            if (!$j(".grants-instructions").hasClass("--sticky)")) {
                scrollTo += $j(".grants-instructions").outerHeight();
            }
            $j('html, body').animate({
                scrollTop: scrollTo
            }, 500);
        } else {
            console.log($j(hash).offset().top);
        }

    });

    $j(".tag-img").on("click", function () {
        $j('html, body').animate({
            scrollTop: $j(".hero-section").outerHeight() + 45
        }, 500);
    });
}


// - - - Smooth scroll on external anchor link, its annoying but im keeping in case - - -
function smoothScrollOnLoad() {
    var hash = location.hash;
    var scrollTo = 0;
    if (hash == "#apply") {
        scrollTo += $j(".hero-section").outerHeight();
        scrollTo += $j(".desc-section").outerHeight();
        scrollTo += $j(".grants-instructions").outerHeight();
    } else {
        console.log($j(".filters").offset().top);
        scrollTo = $j(".filters").offset().top - $j(".site-header").height() - 6;
    }

    $j('a[href ^= "' + hash + '"],.filter-item[data-filter="' + hash + '"]').addClass('active');

    $j('html, body').animate({
        scrollTop: scrollTo
    }, 500);
}


// - - - Navigation - - -
function navigation() {
    $j('.main-navigation a:not([href^="#"])').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        changePushState($(this).attr('href'));
    });
}


// - - - Chnage Push State - - -
function changePushState(page) {
    window.history.pushState(baseURL, page, baseURL + page);
}


// - - - Animate blocks - - -
function animateBlocks() {
    $j('.site-body').on("scroll touchstart resize", function () {
        $j(".js-animated-block").each(function () {

            // ----- same shift all the time ------
            //addAnimationClassIfInViewport($j(this), 400);

            // belows are examples.
            // Use as many if as required or simply stay with basic shift for all animations like above
            // do not use 2 diff if else. All must be under the same if else

            // ----- mobile VS desktop example ------
            if (window.innerWidth > 767) {
                addAnimationClassIfInViewport($j(this), 0)
            } else {
                addAnimationClassIfInViewport($j(this), 0);
            }

            // ----- diff page example ------
            /* if ( $j(this).hasClass("latest-feed")) {
              addAnimationClassIfInViewport($j(this), 300)
            } else {
              addAnimationClassIfInViewport($j(this), 400);
            }*/

        })
    }).trigger("resize");
}


// - - - Check if element is in viewport - - -
function isInViewport(elem, shift) {
    var elementTop = elem.offset().top + shift;
    var elementBottom = elementTop + elem.outerHeight();
    var viewportTop = $j(window).scrollTop();
    var viewportBottom = viewportTop + $j(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};

function isAboveViewport(elem, shift) {
    var elementTop = elem.offset().top + shift;
    var elementBottom = elementTop + elem.outerHeight();
    var viewportTop = $j(window).scrollTop();
    var viewportBottom = viewportTop + $j(window).height();
    return elementBottom < (viewportTop + 400);
};


// - - - Add class to animated block - - -
function addAnimationClassIfInViewport(elem, shift) {
    if (isInViewport(elem, shift)) {
        $j(".grants-instructions.--fixed-container > div").removeClass("--once").removeClass("--open");
        $j(".grants-instructions.--fixed-container").removeClass("--sticky");
        $j(".grants-instructions.--fixed-container").css("display", "none");
    } else if (isAboveViewport(elem, shift)) {
        $j(".grants-instructions.--fixed-container").css("display", "block");
        $j(".grants-instructions.--fixed-container").addClass("--sticky");
        var headerHeight = $j(".site-header").height();
        $j(".grants-instructions.--fixed-container > div").css("top", headerHeight);
        
    } else {
        //elem.removeClass("animated-block");  // <- comment this out to remove the reverse animation
    }
}


// - - - Init all slick - - -
function initSlick() {
    $j('.slick-slider').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: true,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
}


// - - - Init Slick Workshops- - -
function initSlickWorkshops() {
    $j('.workshops .slick-slider').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
}

// - - - Init Slick Partners for good- - -
function initSlickPartnersforgood() {
    $j('.partnersforgood .slick-slider.slider-images').slick({
        infinite: false,
        slidesToShow: 2.1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        appendArrows: $j('.partnersforgood .arrows'),
        asNavFor: '.partnersforgood .slick-slider.slider-texts',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    dots: false,
                    slidesToShow: 1.1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    slidesToShow: 1.2,
                }
            }
        ]
    });

    $j('.partnersforgood .slick-slider.slider-texts').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        fade: true,
        asNavFor: '.partnersforgood .slick-slider.slider-images',
    });
}


// - - - Init Slick Initiatives- - -
function initSlickinitiatives() {
    $j('.past-initiatives .slick-slider.slider-images').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "300px",
        infinite: true,
        dots: false,
        arrows: true,
        variableWidth: true,
        appendArrows: $j('.past-initiatives .arrows'),
        asNavFor: '.past-initiatives .slick-slider.slider-texts',
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    centerPadding: '0px',
                    dots: true
                }
            }
        ]
    });

    $j('.past-initiatives .slick-slider.slider-texts').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        infinite: true,
        dots: true,
        arrows: false,
        fade: true,
        asNavFor: '.past-initiatives .slick-slider.slider-images',
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    dots: false
                }
            }
        ]
    });

    $j('.past-initiatives .slick-slider').on('init', function (event, slick, currentSlide, nextSlide) {
        $j(window).trigger("resize");
    });
    $j('.past-initiatives .slick-slider img').on('load', function (event) {
        $j(window).trigger("resize");
    });

    $j(window).on("resize", function () {
        var dots_width = $j(".slick-dots").width();
        var image_width = $j(".slider-images .slick-current").width();
        var image_height = $j(".slider-images .slick-current").height();
        if ($j(window).width() < 1000) {
            $j(".past-initiatives .arrows").width("100%").css("top", 48);
        } else {
            $j(".past-initiatives .arrows").width(image_width + 60).css("top", image_height / 2 + 36);
        }

        // $j(".grants-instructions").attr("style","");
        // var h = $j(".grants-instructions > div").height();
        // $j(".grants-instructions").height(h);


    }).trigger("resize");
}

// - - - Init Slick Resources Claim Your- - -
function initSlickResourcesClaimYour() {
    $j('.resources-claimyour .slick-slider.slider-images').slick({
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        appendArrows: $j('.resources-claimyour .arrows'),
        asNavFor: '.resources-claimyour .slick-slider.slider-texts',
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                }
            }
        ]
    });

    $j('.resources-claimyour .slick-slider.slider-texts').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        fade: true,
        asNavFor: '.resources-claimyour .slick-slider.slider-images',
    });
}

// - - - Init Slick Resources Past Initiative- - -
function initSlickResourcesPastInitiative() {
    $j('.resources-pastinitiative .slick-slider.slider-images').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        appendArrows: $j('.resources-pastinitiative .arrows'),
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    dots: true,
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    dots: true,
                    slidesToShow: 2.2,
                }
            },
            {
                breakpoint: 1050,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    dots: true,
                    slidesToShow: 1.1,
                    appendDots: $j('.resources-pastinitiative .dots'),
                    infinite: false,
                }
            }
        ]
    });

}

// - - - Accordion - - -
function accordion() {
    $j('.accordion-title').on('click', function (e) {
        e.preventDefault();
        var ctn = $j(this).parents('.accordion');
        console.log(ctn);
        ctn.toggleClass('expanded');
        ctn.find('.content').slideToggle(400);
    });
}


// - - - Play hero banner video when clicking on the hero banner img - - -
function playYTVideo() {
    $j(".js-video-banner-img").on("click", function () {
        var iframe = $j(this).closest('.js-hero-banner').find('iframe');
        iframe[0].src += "&autoplay=1";
        $j(this).hide();
    })
}

// - - - Video Modal Events - - -
function videoModalEvents(){

    $j('.js-video-modal').click(function(e){
        if ($j("body").hasClass("--cookie-optout")) { return true; }
        e.preventDefault();

        var videoURL = $j(this).attr('data-url');
        var videoOrientation = $j(this).attr('data-orientation');
        $j('.modal__video-ctn .video-container').html('<iframe src="https://www.youtube.com/embed/' + videoURL + '?enablejsapi=1&rel=0&playlist=' + videoURL + '&autoplay=1" title="YouTube video player" frameborder="0" allow="autoplay; accelerometer; encrypted-media; gyroscope;" allowfullscreen modestbranding></iframe>');
        $j('.modal').addClass('-active');

        if (videoOrientation === 'portrait') {
            $j('.modal').addClass('-portrait');
        }
        $j('body').trigger("resize");
    })

    $j('.modal__bg,.modal__close-btn').click(function () {
        $j('.modal').removeClass('-active');
        $j('.modal__video-ctn .video-container').html('');
        $j('.stay-connected').focus();

        if ($j('.modal').hasClass('-portrait')) {
            $j('.modal').removeClass('-portrait');
        }
    })
}

//
function initResourceFilters() {
    $j(".js-resource-grid").on("click", ".filters .filter-item", function () {
        var filter = $j(this).data("filter");
        var filterGroup = $j(this).data("filter_group");

        //update active filter bar
        $j(".filter-item").removeClass("active");
        $j(this).addClass("active");

        updateResourceGridFilter(filter, filterGroup);

    });
    $j(".js-load-more").show().on("click", function () { updateResourceGridPage(2); });

    //direct link
    if (window.location.hash != "") {
        $j(".filters .filter-item[data-filter=" + window.location.hash.substr(1) + "]").trigger("click");
        //console.log($j(".filters .filter-item[data-filter="+window.location.hash.substr(1)+"]"));
    }

    $j(".filter-toggle:visible").next().next().addClass("-hide");
    $j(".js-filter-toggle").on("click", function () {
        $j(".filters ul").toggleClass("-hide");
    });
}

function updateResourceGridPage(page) {

    var perPage = 8;
    var index = 0;
    var shown = 0;
    var filter = $j(".js-resource-grid .listing").data("filter");

    $j(".js-resource-grid .listing").data("page", page);

    $j(".grid-item").each(function (i) {
        if ($j(this).find("." + filter).length > 0 || filter == null) {
            /*if ($j(this).hasClass("--1-wide")) {
                index += 1;
            } else if ($j(this).hasClass("--2-wide")) {
                index += 2;
            }
            */
            if (i == 2 || i == 3 || i == 8 || i == 9) {
              index += 2;
            } else {
              index += 1;
            }

            if (index <= perPage * page) {
                shown = index;
                var that = this;
                setTimeout(function () {
                    if ($j(that).hasClass("-hide")) { $j(that).hide().removeClass("-hide"); }



                    $j(that).fadeIn(500);
                }, 500);
            }
        }
    });

    console.log(index + " " + shown);
    if (index <= shown) {
        $j(".js-load-more").hide();
    } else {
        $j(".js-load-more").show().off("click").on("click", function () { updateResourceGridPage(page + 1); });
    }
}

function updateResourceGridFilter(filter = null, filterGroup = null) {

    var perPage = 8;
    var index = 0;
    var shown = 0;
    var page = 1;

    $j(".js-resource-grid .listing").data("page", page);
    $j(".js-resource-grid .listing").data("filter", filter);

    //show current filter cards
    $j(".grid-item").each(function (i) {
        $j(this).fadeOut(500);
        if ($j(this).find("." + filter).length > 0 || filter == null) {
            /* if ($j(this).hasClass("--1-wide")) {
                index += 1;
            } else if ($j(this).hasClass("--2-wide")) {
                index += 2;
            } */
            if (i == 2 || i == 3 || i == 8 || i == 9) {
              index += 2;
            } else {
              index += 1;
            }

            if (index <= perPage) {
                shown = index;
                var that = this;
                setTimeout(function () {
                    if ($j(that).hasClass("-hide")) { $j(that).hide().removeClass("-hide"); }

                    if (filter == null || filter == "") {
                      $j(".grid-item").removeClass("--2-wide").addClass("--1-wide");
                      $j(".grid-item").eq(2).addClass("--2-wide");
                      $j(".grid-item").eq(3).addClass("--2-wide");
                      $j(".grid-item").eq(8).addClass("--2-wide");
                      $j(".grid-item").eq(9).addClass("--2-wide");
                    } else {
                      $j(".grid-item").removeClass("--2-wide").addClass("--1-wide");
                      $j(".grid-item:has(."+filter+")").eq(2).addClass("--2-wide");
                      $j(".grid-item:has(."+filter+")").eq(3).addClass("--2-wide");
                    }

                    $j(that).fadeIn(500);
                }, 500);
            }
        }
    });

    //show card group
    /*
    setTimeout(function () {
      $j(".grid-item .filter-item").hide();
      $j(".grid-item .filter-item."+filterGroup).show();
    }, 250);*/

    if (index <= shown) {
        $j(".js-load-more").hide();
    } else {
        $j(".js-load-more").show().off("click").on("click", function () { updateResourceGridPage(page + 1); });
    }

    $j(".filter-toggle:visible").next().addClass("-hide");
}


function addMenuActiveClass() {
    const currentPage = $j('.site-body').attr('data-page');
    const menuItems = document.querySelectorAll('.menu__item');

    if (!currentPage) {
        return;
    }

    menuItems.forEach((menuItem, index) => {
        const menuLink = menuItem.querySelector('a');
        if (menuLink) {
            if (menuLink.getAttribute('data-page') === currentPage) {
                menuItem.classList.add('active');
            }
        }
    });
}

function mobileMenuToggle() {
    $j('.menu__toggle').click(function () {
        $j('.site-header__menu-ul').toggleClass('open');
        $j('.site-header__menu-ul-buttons').toggleClass('open');
        $j('.js-open, .js-close').toggleClass('is-visible');
    })
}

function onClickLoadMoreButton() {
    $j('.js-load-more-btn').click(function () {
        $j(this).hide();
        updateLoadMoreItemDisplay();
    });
}

function updateLoadMoreItemDisplay() {
    $j('.js-load-more-items').addClass("--show");
}

function initInstructions() {
    $j(".chapter-nav__item a, .chapter-link").on("click", function (e) {
        e.stopPropagation();
        e.preventDefault();
        if ($j(".chapter-contents").hasClass("--animating")) { return false; }

        var target = $j(this).attr("href").substr(1);
        $j(".chapter-nav__item").removeClass("active");
        $j(".chapter-nav__item a[href='#" + target + "']").parent().addClass("active");

        $j(".chapter-contents").addClass("--animating");
        $j(".chapter-contents .chapter").each(function () {
            $j(this).removeClass("active");
            $j(this).find(".--scrollable").scrollTop(0);
            $j(this).fadeOut(100);
        });
        if (window.innerWidth < 1000) {
          if ($j(".grants-instructions.--sticky").length >= 1) {
            $j(".grants-instructions.--sticky > div.--open .ctn-main").scrollTop(0);
          } else {
            var scrollTo = 0;
            scrollTo += $j(".hero-section").outerHeight();
            scrollTo += $j(".desc-section").outerHeight();
            scrollTo += $j(".chapter-nav").outerHeight();
            console.log(scrollTo);
            $j('html, body').animate({
                scrollTop: scrollTo
            }, 500);
            $j('html, body').scrollTop(scrollTo);
          }
        }

        setTimeout(function () {
            $j(".chapter-contents .chapter__" + target).fadeIn(400).addClass("active");
            setTimeout(function () {
                $j(".chapter-contents").removeClass("--animating");
            }, 400);
        }, 100);
    });
}

function tockButtonStyle() {
    let button = $j('div#Tock_widget_container>div.TockWidgetWrapper .TockButton-white');

    if (button.length > 0) {
        button.css({
            'background-color': '#e1cfe6',
            'text-transform': 'uppercase',
        });
        button.on('mouseenter',
            function () {
                $j(this).css('background-color', '#ffffff');
            }).on('mouseleave',
                function () {
                    $j(this).css('background-color', '#e1cfe6');
                });
    }
}

function redirectTo( currentPath, target) {
    if (window.location.pathname === currentPath) {
        window.location.replace(target);
    }
} 